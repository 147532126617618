import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const payload = useStaticQuery(
        graphql`
            query {
                wpPage(title: { eq: "Work" }) {
                    work_page {
                        portfolioBackgroundColor
                        workBackgroundColor
                        workTextColor
                        workTitle
                    }
                }
                allWpProject(filter: { title: { ne: "Settings" } }) {
                    edges {
                        node {
                            categories {
                                nodes {
                                    name
                                }
                            }
                            project {
                                ordering
                                featuredProject
                                textColor
                                cardTextColor
                                cardBackgroundColour
                                overlayColor
                                overlayOpacity
                                projectCardImage {
                                    sourceUrl
                                    localFile {
                                        extension
                                    }
                                }
                                projectCardBackground {
                                    localFile {
                                        childImageSharp {
                                            id
                                            gatsbyImageData(
                                                layout: CONSTRAINED
                                                width: 1600
                                            )
                                        }
                                    }
                                }
                                projectCardBackgroundSmall {
                                    localFile {
                                        childImageSharp {
                                            id
                                            gatsbyImageData(
                                                layout: CONSTRAINED
                                                width: 800
                                            )
                                        }
                                    }
                                }
                                projectHeroImage {
                                    title
                                    sourceUrl
                                }
                                projectTagline
                            }
                            title
                            slug
                        }
                    }
                }
                allWpCategory {
                    edges {
                        node {
                            name
                        }
                    }
                }
            }
        `
    )
    return payload
}
