import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const payload = useStaticQuery(
        graphql`
            query {
                allWpProject {
                    edges {
                        node {
                            project {
                                textColor
                                cardBackgroundColour
                                cardTextColor
                                overlayColor
                                overlayOpacity
                                projectCardImage {
                                    sourceUrl
                                    localFile {
                                        extension
                                    }
                                }
                                projectCardBackground {
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(layout: CONSTRAINED)
                                        }
                                    }
                                }
                                projectCardBackgroundSmall {
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(layout: CONSTRAINED)
                                        }
                                    }
                                }
                                projectTagline
                            }
                            title
                            slug
                            categories {
                                nodes {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return payload
}
