// extracted by mini-css-extract-plugin
export const portfolioBoxWork = "portfolio-module--portfolio-box-work--XQab5";
export const imageWrapper = "portfolio-module--image-wrapper--3uLiJ";
export const portfolioBoxWorkClosed = "portfolio-module--portfolio-box-work-closed--2LZdl";
export const titleHolderWork = "portfolio-module--title-holder-work--BNF-a";
export const portfolioBoxWorkSmall = "portfolio-module--portfolio-box-work-small--2VYoW";
export const portfolioBoxWorkSmallClosed = "portfolio-module--portfolio-box-work-small-closed--1BGJQ";
export const titleHolderWorkSmall = "portfolio-module--title-holder-work-small--zIdnR";
export const spaceExpanderWork = "portfolio-module--space-expander-work--3qXQg";
export const innerWrapper = "portfolio-module--inner-wrapper--1Tp6W";
export const workProjectContainer = "portfolio-module--work-project-container--3N4ZQ";
export const workProjectContainerSmall = "portfolio-module--work-project-container-small--2Xa54";
export const taglineBoxWork = "portfolio-module--tagline-box-work--36JHa";
export const serviceBoxWork = "portfolio-module--service-box-work--fnt2Y";
export const carouselContainer = "portfolio-module--carousel-container--1HvMk";
export const portfolioBox = "portfolio-module--portfolio-box--3Kfic";
export const titleHolder = "portfolio-module--title-holder--2Ukmk";
export const ulContainer = "portfolio-module--ul-container--3kro7";
export const projectContainer = "portfolio-module--project-container--qYOjS";
export const logoBox = "portfolio-module--logo-box--1w_C8";
export const projectBox = "portfolio-module--project-box--51Z59";
export const taglineBox = "portfolio-module--tagline-box--2qRx4";
export const serviceBox = "portfolio-module--service-box--1kL2Q";
export const service = "portfolio-module--service--28Aw8";
export const serviceTypes = "portfolio-module--service-types--2ejD6";