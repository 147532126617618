import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Work from '../components/work'
import useWorkData from '../components/custom-hooks/useWorkData'

const WorkPage = () => {
    const payload = useWorkData()
    return (
        <Layout
            color={payload.wpPage.work_page.workTextColor}
            backgroundColor={payload.wpPage.work_page.workBackgroundColor}
        >
            <SEO title="Work" />
            <Work payload={payload} />
        </Layout>
    )
}

export default WorkPage
