import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Carousel from 'react-multi-carousel'
import {
    CustomDot,
    CustomLeftArrow,
    CustomRightArrow,
} from '../project-components/chapter-types/scroller-base'
import { injectSanitiseHtml, isMobileSize } from '../../utils'
import useWindowSize from '../custom-hooks/useWindowSize'
import useMouseOver from '../custom-hooks/useMouseOver'
import usePortfolioData from '../custom-hooks/usePortfolioData'

import 'react-multi-carousel/lib/styles.css'
import * as portfolioStyles from './portfolio.module.scss'

const isTooLongText = (limit, text) => {
    if (text.length >= limit) {
        return true
    }
    return false
}

const PortfolioPage = (props) => {
    const payload = usePortfolioData()
    const containerId = 'multi-carousel'
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    }

    const [CarouselRef, setCarouselRef] = useState(null)
    const [projectsOnCarousel, setProjectsOnCarousel] = useState(
        payload.allWpProject.edges
    )
    const [resetGuard, setResetGuard] = useState(true)
    const [pageSetFlag, setpageSetFlag] = useState(false)
    const isMouseOver = useMouseOver(containerId)

    const getOrderOfProject = (name) => {
        let order = 1
        const titlesInOrder = payload.allWpProject.edges.map(
            (edge) => edge.node.title
        )
        titlesInOrder.forEach((element, index) => {
            if (element === name) {
                order = index
            }
        })
        if (CarouselRef) {
            CarouselRef.goToSlide(order)
            setpageSetFlag(true)
        }
    }
    if (props.relatedTitle !== '' && !pageSetFlag) {
        getOrderOfProject(props.relatedTitle)
    }

    if (
        props.projectsToShow.length > 0 &&
        projectsOnCarousel.length === payload.allWpProject.edges.length
    ) {
        const projectCollection = []
        payload.allWpProject.edges.forEach((edge) => {
            if (props.projectsToShow.includes(edge.node.title)) {
                projectCollection.push(edge)
            }
        })
        if (resetGuard) {
            setProjectsOnCarousel(projectCollection)
            setResetGuard(false)
        }
    }

    return (
        <div className={portfolioStyles.carouselContainer} id={containerId}>
            <Carousel
                ref={(el) => setCarouselRef(el)}
                responsive={responsive}
                showDots
                renderDotsOutside
                customDot={<CustomDot />}
                customLeftArrow={<CustomLeftArrow isMouseOver={isMouseOver} />}
                customRightArrow={
                    <CustomRightArrow isMouseOver={isMouseOver} />
                }
                minimumTouchDrag={10}
                containerClass={portfolioStyles.ulContainer}
                removeArrowOnDeviceType={['mobile']}
            >
                {projectsOnCarousel.map((edge, index) => (
                    <PortfolioCard
                        // style={{ transform: `translate(${xTranslate}px` }}
                        slug={edge.node.slug}
                        backgroundColor={edge.node.project.cardBackgroundColour}
                        title={edge.node.title}
                        tagLine={edge.node.project.projectTagline}
                        categories={
                            edge.node.categories ? edge.node.categories : []
                        }
                        cardImage={edge.node.project.projectCardImage}
                        cardImageType={
                            edge.node.project.projectCardImage
                                ? edge.node.project.projectCardImage.localFile
                                      .extension
                                : null
                        }
                        cardBackground={edge.node.project.projectCardBackground}
                        cardBackgroundSmall={
                            edge.node.project.projectCardBackgroundSmall
                        }
                        textColor={edge.node.project.textColor}
                        cardTextColor={edge.node.project.cardTextColor}
                        index={index}
                        key={`portfoliocard-elem-${index}`}
                    />
                ))}
            </Carousel>
        </div>
    )
}

PortfolioPage.defaultProps = {
    relatedTitle: '',
    projectsToShow: [],
}

export const PortfolioCard = (props) => {
    let boxStyle = portfolioStyles.portfolioBox
    let backgroundImage = props.cardBackground
    let backgroundImageWidth = 1600
    let titleHolder = portfolioStyles.titleHolder
    let transitionDelay = {}
    if (props.context === 'work') {
        boxStyle = props.runAnimation
            ? portfolioStyles.portfolioBoxWork
            : portfolioStyles.portfolioBoxWorkClosed
        titleHolder = portfolioStyles.titleHolderWork
    }
    if (props.context === 'work' && !props.isFeatured) {
        boxStyle = props.runAnimation
            ? portfolioStyles.portfolioBoxWorkSmall
            : portfolioStyles.portfolioBoxWorkSmallClosed
        titleHolder = portfolioStyles.titleHolderWorkSmall
        backgroundImage = props.cardBackgroundSmall || props.cardBackground
        backgroundImageWidth = 800
        transitionDelay = { transitionDelay: `${0.3 * props.index}s` }
    }

    const [slideDistance, setSlideDistance] = useState(0)

    const examineClick = (event) => {
        if (event.type === 'mousedown') {
            setSlideDistance(event.pageX)
        }
        if (event.type === 'mouseup' && event.pageX === slideDistance) {
            navigate(`/${props.slug}`)
        }
    }
    return (
        <article
            className={boxStyle}
            style={{
                ...props.style,
                ...transitionDelay,
                color: props.cardTextColor,
            }}
            onMouseDown={examineClick}
            onMouseUp={examineClick}
            id={props.animation.id}
            key={`portfoliocard-${props.title}`}
        >
            <div className={titleHolder}>
                <h1 style={{ color: props.cardTextColor }}>{props.title}</h1>
            </div>
            <div
                className={portfolioStyles.imageWrapper}
                style={{
                    position: 'absolute',
                    zIndex: 1,
                    backgroundColor: props.overlayColor,
                    opacity: props.overlayOpacity
                        ? props.overlayOpacity / 100
                        : 1,
                }}
            />
            <div
                className={portfolioStyles.imageWrapper}
                style={{
                    background: props.backgroundColor,
                }}
            >
                <GatsbyImage
                    image={
                        backgroundImage.localFile.childImageSharp.gatsbyImageData
                    }
                    key={backgroundImage.localFile.childImageSharp.gatsbyImageData}
                    draggable={false}
                    className={portfolioStyles.innerWrapper}
                    width={backgroundImageWidth}
                    loading="eager"
                />
            </div>
            <ProjectInfoCard {...props} />
        </article>
    )
}

PortfolioCard.defaultProps = {
    context: 'home',
    isFeatured: 'true',
    animation: {},
    runAnimation: false,
    index: 0,
}

const ProjectInfoCard = (props) => {
    let articleStyle = portfolioStyles.projectContainer
    let serviceStyle = portfolioStyles.serviceBox
    let taglineStyle = portfolioStyles.taglineBox
    if (props.context === 'work') {
        articleStyle = portfolioStyles.workProjectContainer
        serviceStyle = portfolioStyles.serviceBoxWork
        taglineStyle = portfolioStyles.taglineBoxWork
        if (!props.isFeatured) {
            articleStyle = portfolioStyles.workProjectContainerSmall
        }
    }

    const size = useWindowSize()
    const taglineType = isTooLongText(50, props.tagLine) ? 'h3' : 'h2'

    const renderLogo = () => {
        if (!props.cardImageType) {
            return null
        }
        if (props.cardImageType === 'png') {
            return (
                <img
                    src={props.cardImage ? props.cardImage.sourceUrl : null}
                    draggable="false"
                    alt=""
                />
            )
        }
        if (props.cardImageType === 'svg') {
            return (
                <SVG
                    src={props.cardImage.sourceUrl}
                    cacheRequests
                    // preProcessor={code =>
                    //     code.replace(/fill="#.*"/g, `fill="${props.color}"`)
                    // }
                />
            )
        }
    }

    return (
        <div className={articleStyle}>
            <div className={portfolioStyles.projectBox}>
                <div className={taglineStyle}>
                    {injectSanitiseHtml(props.tagLine, taglineType)}
                </div>
                <div className={serviceStyle}>
                    <div className={portfolioStyles.service}>
                        <p>Services</p>
                    </div>
                    <div className={portfolioStyles.serviceTypes}>
                        {props.categories.nodes.map((category) => (
                            <p key={category.name}>{category.name}</p>
                        ))}
                    </div>
                </div>
            </div>

            <div
                className={portfolioStyles.logoBox}
                style={{
                    background: props.backgroundColor,
                }}
            >
                {renderLogo()}
            </div>
        </div>
    )
}

export default PortfolioPage
