import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-modal'
import sanitizeHtml from 'sanitize-html'
import { Link } from 'gatsby'
import { PortfolioCard } from './home-components/portfolio'
import { isMobileSize, allowance } from '../utils'
import ScrollContext from './context/ScrollContext'
import {
    effects,
    useAnimationManager,
} from './custom-hooks/useAnimationManager'
import useWindowSize from './custom-hooks/useWindowSize'

import './modal.css'

import * as workStyles from './work.module.scss'

Modal.setAppElement('#___gatsby')

const WorkPage = ({ payload }) => {
    const [categoryState, setCategory] = useState('All Projects')
    const [isRolldown, setRolldown] = useState(false)
    const size = useWindowSize()
    const scrollY = useContext(ScrollContext)

    useEffect(() => {
        if (isRolldown && !isMobileSize(size.width)) {
            // console.log('Its desktop so I turn of the modal')
            setRolldown(false)
        }
    }, [isRolldown, size])

    const animatedElements = {
        runOpeningAnimation: {
            id: '',
            effect: effects.ON_OPEN,
        },
    }

    const shouldRenderProject = categories => {
        let render = false
        if (categoryState === 'All Projects') {
            render = true
        } else {
            categories.forEach(category => {
                if (category.name === categoryState) {
                    render = true
                }
            })
        }
        return render
    }

    const orderProjects = projects => {
        const orderedProjects = []

        const order = projects.map(project => project.node.project.ordering)
        order.sort((a, b) => a - b)
        let projectsYetToSort = [...projects]
        order.forEach(orderNumber => {
            let didFind = false
            let findIndex = null
            projectsYetToSort.forEach((project, index) => {
                if (orderNumber === project.node.project.ordering && !didFind) {
                    orderedProjects.push(project)
                    didFind = true
                    findIndex = index
                }
            })
            if (didFind && findIndex) {
                projectsYetToSort.splice(findIndex, 1)
                didFind = false
                findIndex = null
            }
        })
        return orderedProjects
    }

    let featuredProjects = []
    let notFeaturedProjects = []
    let iFeatured = 0
    payload.allWpProject.edges.forEach(edge => {
        if (edge.node.project.featuredProject) {
            featuredProjects.push(edge)
            animatedElements[`featuredProjectNo${iFeatured}`] = {
                id: `featured-project-${iFeatured}`,
                effect: effects.ON_MID_REACHED,
            }
            iFeatured += 1
        }
    })
    let iNFeatured = 0
    payload.allWpProject.edges.forEach(edge => {
        if (!edge.node.project.featuredProject) {
            notFeaturedProjects.push(edge)
            animatedElements[`nonFeaturedProjectNo${iNFeatured}`] = {
                id: `non-featured-project-${iNFeatured}`,
                effect: effects.ON_MID_REACHED,
            }
            iNFeatured += 1
        }
    })

    featuredProjects = orderProjects([...featuredProjects])
    notFeaturedProjects = orderProjects([...notFeaturedProjects])

    const animationStates = useAnimationManager(animatedElements, scrollY)

    const modalOverlay = {
        position: 'fixed',
        width: '100%',
        maxWidth: '100%',
        // top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100vh',
        backgroundColor: 'rgba(46,45,45,0.7)',
        zIndex: 150,
    }

    const modalContent = {
        position: 'fixed',
        width: '100%',
        maxWidth: '100%',
        top: '9%',
        left: 0,
        right: 0,
        bottom: 0,
        padding: '20% 15%',
        backgroundColor: 'white',
        opacity: '1',
        zIndex: 100,
        // marginTop: '100vh',
    }

    const setRolldownMenu = () => {
        if (!isRolldown) {
            setRolldown(true)
        }
    }

    const getAnimatedHeader = (html, color) => {
        const cleanHtml = sanitizeHtml(html, allowance)
        const pieces = cleanHtml.split('<br />')
        return pieces.map(element => (
            <div
                className={
                    animationStates.runOpeningAnimation
                        ? workStyles.headerBox
                        : workStyles.headerBoxClosed
                }
                key={element.replace(/[\r\n]+/gm, '')}
            >
                <h1
                    style={{
                        color: color,
                    }}
                >
                    {element.replace(/[\r\n]+/gm, '')}
                </h1>
            </div>
        ))
    }

    return (
        <div>
            <div
                style={{
                    backgroundColor:
                        payload.wpPage.work_page.workBackgroundColor,
                    color: payload.wpPage.work_page.workTextColor,
                }}
                className={workStyles.greeterContainer}
            >
                <div className={workStyles.titleContainer}>
                    {getAnimatedHeader(
                        payload.wpPage.work_page.workTitle,
                        payload.wpPage.work_page.workTextColor
                    )}
                </div>
            </div>
            {/* <div
                className={workStyles.categorySelector}
                style={{
                    backgroundColor:
                        payload.wpPage.work_page.workBackgroundColor,
                    color: payload.wpPage.work_page.workTextColor,
                }}
            >
                <p>Project Type</p>
                <div className={workStyles.categories}>
                    <p
                        onClick={() => setCategory('All Projects')}
                        style={
                            'All Projects' === categoryState
                                ? { fontWeight: 'bold', color: '#0064ff' }
                                : { color: '#a4a4a4' }
                        }
                    >
                        All Projects
                    </p>
                    {payload.allWpCategory.edges.map(edge => (
                        <p
                            style={
                                edge.node.name === categoryState
                                    ? { fontWeight: 'bold', color: '#0064ff' }
                                    : { color: '#a4a4a4' }
                            }
                            key={`category-${edge.node.name}`}
                            onClick={() => setCategory(edge.node.name)}
                        >
                            {edge.node.name}
                        </p>
                    ))}
                </div>
            </div> */}
            <div className={workStyles.mobileCategorySelector}>
                <div>
                    <p
                        onClick={() => {
                            setRolldownMenu()
                        }}
                        style={{ fontWeight: 'bold', color: '#0064ff' }}
                    >
                        {categoryState} <i className={workStyles.down}></i>
                    </p>
                </div>
            </div>
            <Modal
                isOpen={isRolldown}
                contentLabel="Select Category"
                style={{ overlay: modalOverlay, content: modalContent }}
                shouldCloseOnOverlayClick={true}
                closeTimeoutMS={1000}
            >
                <span
                    onClick={() => {
                        setRolldown(!isRolldown)
                    }}
                    className={workStyles.close}
                />
                <p
                    onClick={() => {
                        setCategory('All Projects')
                        setRolldown(!isRolldown)
                    }}
                    style={
                        'All Projects' === categoryState
                            ? {
                                  fontWeight: 'bold',
                                  color: '#0064ff',
                                  fontFamily: 'Roboto',
                              }
                            : { color: '#a4a4a4', fontFamily: 'Roboto' }
                    }
                >
                    All Projects
                </p>
                {payload.allWpCategory.edges.map(edge => (
                    <p
                        style={
                            edge.node.name === categoryState
                                ? {
                                      fontWeight: 'bold',
                                      color: '#0064ff',
                                      fontFamily: 'Roboto',
                                  }
                                : { color: '#a4a4a4', fontFamily: 'Roboto' }
                        }
                        key={`category2-${edge.node.name}`}
                        onClick={() => {
                            setCategory(edge.node.name)
                            setRolldown(!isRolldown)
                        }}
                    >
                        {edge.node.name}
                    </p>
                ))}
            </Modal>
            <section
                className={workStyles.portfolioContainer}
                style={{
                    backgroundColor:
                        payload.wpPage.work_page.portfolioBackgroundColor,
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '0',
                        width: '100%',
                        maxWidth: '100%',
                        height: '80px',
                        background:
                            payload.wpPage.work_page.workBackgroundColor,
                    }}
                ></div>
                {featuredProjects &&
                    featuredProjects.map((edge, index) =>
                        shouldRenderProject(edge.node.categories) ? (
                            <PortfolioCard
                                slug={edge.node.slug}
                                backgroundColor={
                                    edge.node.project.cardBackgroundColour
                                }
                                title={edge.node.title}
                                heroImage={edge.node.project.projectHeroImage}
                                tagLine={edge.node.project.projectTagline}
                                categories={
                                    edge.node.categories
                                        ? edge.node.categories
                                        : []
                                }
                                cardImage={edge.node.project.projectCardImage}
                                cardImageType={
                                    edge.node.project.projectCardImage
                                        ? edge.node.project.projectCardImage
                                              .localFile.extension
                                        : null
                                }
                                cardBackground={
                                    edge.node.project.projectCardBackground
                                }
                                cardBackgroundSmall={
                                    edge.node.project.projectCardBackgroundSmall
                                }
                                textColor={edge.node.project.textColor}
                                cardTextColor={edge.node.project.cardTextColor}
                                context={'work'}
                                overlayColor={edge.node.project.overlayColor}
                                overlayOpacity={edge.node.project.overlayOpacity}
                                isFeatured={edge.node.project.featuredProject}
                                animation={
                                    index === 0
                                        ? animationStates.runOpeningAnimation
                                        : animatedElements[
                                              `featuredProjectNo${index}`
                                          ]
                                }
                                runAnimation={
                                    index === 0
                                        ? animationStates.runOpeningAnimation
                                        : animationStates[
                                              `featuredProjectNo${index}`
                                          ]
                                }
                                index={index}
                                key={`portfolio-work-featured-card-${index}`}
                            />
                        ) : null
                    )}
            </section>
            <section
                className={workStyles.portfolioContainerNonFeatured}
                style={{
                    backgroundColor:
                        payload.wpPage.work_page.portfolioBackgroundColor,
                }}
            >
                {notFeaturedProjects &&
                    notFeaturedProjects.map((edge, index) =>
                        shouldRenderProject(edge.node.categories) ? (
                            <PortfolioCard
                                slug={edge.node.slug}
                                backgroundColor={
                                    edge.node.project.cardBackgroundColour
                                }
                                title={edge.node.title}
                                heroImage={edge.node.project.projectHeroImage}
                                tagLine={edge.node.project.projectTagline}
                                categories={
                                    edge.node.categories
                                        ? edge.node.categories
                                        : []
                                }
                                cardImage={edge.node.project.projectCardImage}
                                cardImageType={
                                    edge.node.project.projectCardImage
                                        ? edge.node.project.projectCardImage
                                              .localFile.extension
                                        : null
                                }
                                cardBackground={
                                    edge.node.project.projectCardBackground
                                }
                                textColor={edge.node.project.textColor}
                                cardTextColor={edge.node.project.cardTextColor}
                                context={'work'}
                                overlayColor={edge.node.project.overlayColor}
                                overlayOpacity={edge.node.project.overlayOpacity}
                                isFeatured={edge.node.project.featuredProject}
                                animation={
                                    animatedElements[
                                        `nonFeaturedProjectNo${index}`
                                    ]
                                }
                                runAnimation={
                                    animationStates[
                                        `nonFeaturedProjectNo${index}`
                                    ]
                                }
                                index={index}
                                key={`portfolio-work-nonfeatured-card-${index}`}
                            />
                        ) : null
                    )}
            </section>
            {/* <section
                style={{ background: 'black' }}
                className={workStyles.contactBlock}
            >
                <h3>
                    For new projects or business enquiry<br></br>
                    <Link className={workStyles.contactLink} to="/contact">
                        get in touch
                    </Link>
                </h3>
            </section> */}
            <div style={{ width: '100%', height: '100px' }} />
        </div>
    )
}

export default WorkPage
