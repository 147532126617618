// extracted by mini-css-extract-plugin
export const greeterContainer = "work-module--greeter-container--3JEsZ";
export const titleContainer = "work-module--title-container--3SDXj";
export const headerBox = "work-module--header-box--3gvlf";
export const headerBoxClosed = "work-module--header-box-closed--3ziIi";
export const portfolioContainer = "work-module--portfolio-container--1SxtT";
export const portfolioContainerNonFeatured = "work-module--portfolio-container-non-featured--1eK6d";
export const categorySelector = "work-module--category-selector--2G5Sl";
export const categories = "work-module--categories--pysDG";
export const contactBlock = "work-module--contact-block--2NX3w";
export const contactLink = "work-module--contact-link--83frl";
export const down = "work-module--down--RjWCN";
export const mobileCategorySelector = "work-module--mobile-category-selector--1Svcm";
export const close = "work-module--close--VL8z7";